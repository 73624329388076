import React from 'react';

class TrackFormEn extends React.Component{
	constructor(props) {
		super(props);
		
		this.state = {
			trackNumber: "",
			error: false
		}
	}
	
	handleInputChanged(event) {
		this.setState({
		  trackNumber: event.target.value,
		  error: false
		});
	}
	
	handleButtonClicked() {
		if(this.state.trackNumber !== ""){
			window.open("https://herramientas.raiconet.com/trackingGuias/buscarTrackingVarios?busqueda="+this.state.trackNumber+"&buscar=Buscar",
			'_blank');
		}else{
			this.setState({
				error: true
			});
		}
	}
	
	render (){
		return <>
			<input id="nroEnvio" type="text" className={"form-control "+(this.state.error ? 'error' : '')} placeholder="Tracking number"
			value={this.state.trackNumber}
			onChange={this.handleInputChanged.bind(this)}
			/>
			<button id="buscarEnvio" type="button" className={"btn btn-danger"} 
			onClick={this.handleButtonClicked.bind(this)}
			>TRACK SHIPPING</button>
			</>
	}
}

export default TrackFormEn
import * as React from "react"

import LayoutEn from "../components/layoutEn"
import Warehouses from "../components/warehouses"


const EmpresaPage = () => (
  <LayoutEn
	seccion="empresa"	
	title="Company"	
	linkTo="experienciaContainer"
	>		
		<div id="experienciaContainer">
			<div className="row">
				<div id="expCol" className="col-sm-6">
					<div id="expTxt" className="">
						<div id="expArrow" className="col-sm-1">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg>
						</div>

							<div id="expDesc" className="col-sm-11"
												data-aos='fade-up'
												data-aos-duration="600" data-aos-once="true">
								<div class="txtDesc"><h2>We are experts in<br/>delivering international logistics solutions</h2></div>
								<p>Over 30 years of experience </p>
								<p
								style={{ marginBottom: '40px'}}
								><strong>Raiconet</strong>  delivers top quality, creative and customized <br/>international logistics solutions to match our customers' needs.</p>
								{/*<div id="expFoot">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
	  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
	</svg>
									CONOCER MÁS SOBRE Raiconet
								</div>*/}
							</div>
					</div>
				</div>
				<div id="expImg" className="col-sm-6 empresa expImg" 
								  data-sal="zoom-in"
								  data-sal-delay="0"
								  data-sal-duration="900">
					<div></div>
				</div>
			</div>
		</div>
		<div id="empresaTarjetas" className="raicoContainer mt-30">
			<div className="row">
				<div className="col-12 col-sm-6">
					<div id="correo" className="tarjeta bgRed"
					  data-aos='fade-up'
					  data-aos-duration="600" 
					  data-aos-once="true"
					>
						<div className=" txtTarjeta">
							<br/><h2>Courier and international air cargo</h2><br/>
							<br/>
							<h2>Door-to-airport service</h2>
						</div>
					</div>
				</div>
				<div className="col-12 col-sm-6">
					<div className="tarjeta bgBlue"
					  data-aos='fade-up'
					  data-aos-delay="300" 
					  data-aos-duration="600" 
					  data-aos-once="true"
					>
						<div className=" txtTarjeta">
						<br className="d-block d-sm-none" />
							<h2>Record transit times</h2><br/>
							<br/>
							<h2>Specialists in critical and urgent shipments</h2><br/>
							<br/>
							<h2>Door-to-door service</h2>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Warehouses />	
  </LayoutEn>
)

export default EmpresaPage

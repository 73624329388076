import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import scrollTo from 'gatsby-plugin-smoothscroll'
import HoraMia from "./horaMia"
import HeaderNavEn from "./headerNavEn"
import TrackForm from "./trackFormEn"

const HeaderEn = ({ siteTitle, title, subTitle, seccion, linkTo  }) => {

	return (<>
   <div id="header" className={seccion}>
			<div>
				<HeaderNavEn
					seccion={seccion}
				/>
				<div id="header-title" >
					<motion.div
                        initial={{ opacity: 0, y: '30%' }}
                        animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.6 }}
                    ><h1>
						<span className={"title"}>{title}</span>
						<span className={"title"}>{subTitle}</span>
					</h1>
					<div className="d-inline-block d-sm-none"
					style={{ marginTop: '-10px' }}>
						<HoraMia />
					</div>
					</motion.div>
				</div>
				<div id="header-bottom" className={"row"}>
					<div className={"col-12 col-sm-7 buscarRow"}>
						
						{ seccion == 'exportacion' ?
							<a id="buscarEnvio" className={"btn btn-danger"}
							style={{marginLeft: 0 }}
							href="https://herramientas.raiconet.com/login/auth" target="_blank">LOGIN</a>
						:
							<TrackForm />
						}						
					</div>
					<div className={"col-4 d-none d-sm-flex"}>
						
							<HoraMia />
						
					</div>
					<div className={"col-1 d-none d-sm-flex"}>
						<span className={"flechaAbajo nav-link mv"} onClick={() => scrollTo("#"+linkTo)}>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={"bi bi-arrow-down"} viewBox="0 0 16 16">
							  <path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"></path>
							</svg>
						</span>
					</div>
				</div>
			</div>
		</div>
	
	</>)
}

HeaderEn.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderEn.defaultProps = {
  siteTitle: ``
}

export default HeaderEn
